import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/secondary_pages.scss"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'


import img1 from "../../images/editorial/insights/revenue-cycle-management-choosing-the-right-provider-can-make-all-the-difference-2000x1383.jpg"

import img2 from "../../images/editorial/insights/four-myths-that-fuel-physician-burnout-2000-1383.jpg"


import img3 from "../../images/editorial/insights/three-powerful-ways-independent-physicians-can-banish-admin-burden-2000x1383.jpg"

import img4 from "../../images/editorial/telehealth.jpg"

import img5 from "../../images/editorial/5-tips.jpg"

import img6 from "../../images/editorial/insights/COVID-relief.jpg"

import { Helmet } from "react-helmet"

const SecondPage = () => (
 <Layout styleClass="insights">
  <SEO title="Insights" />
  <section id="sct-pg-hdr" className="container ctr-page-header">
   <h2>Insights</h2>
   <p>Learn how you and your practice can get more out of MSM</p>
  </section>
  <section className="ctr-page-listing">
   <div className="ctr-page-listing-unit">
    <Link to="./news_and_insights/faq-covid-19"><img src={img6} alt="image for article" /></Link>
    <h3>Financial Relief FAQs</h3>
    <p>More answers to your questions about financial relief related to the Coronavirus Pandemic.</p>
    <Link to="./news_and_insights/faq-covid-19">Read more <FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
   </div>

   <div className="ctr-page-listing-unit">
    <Link to="./news_and_insights/financial-relief-covid-19"><img src={img6} alt="image for article" /></Link>
    <h3>Financial Relief for Physician Practices: A Brief Guide for the Coronavirus Pandemic</h3>
    <p>In a short time, COVID-19 has forced physician practices to make big changes: modifying hours of operation, shifting to telemedicine, canceling appointments, and reducing staffing. All of these adjustments translate to reduced revenue. If your group practice is under financial strain, now is the time to seek aid.  </p>
    <Link to="./news_and_insights/financial-relief-covid-19">Read more <FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
   </div>


   <div className="ctr-page-listing-unit">
    <Link to="./news_and_insights/5ways-covid-19"><img src={img5} alt="image for article" /></Link>
    <h3>5 Ways Practice Managers Can Lead During COVID Slowdowns</h3>
    <p>To curb the spread of COVID-19, communities and nations are taking unprecedented measures to reduce non-essential physical interactions.  As a result, many non-emergency healthcare providers and medical groups are seeing significant drops in patient volumes – forcing some to stop services altogether. </p>
    <Link to="./news_and_insights/5ways-covid-19">Read more <FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
   </div>

   <div className="ctr-page-listing-unit">
    <Link to="./news_and_insights/telehealth-covid-19"><img src={img4} alt="image for article" /></Link>
    <h3>Telehealth Quick Start In Response To The COVID-19 Situation</h3>
    <p>The COVID-19 situation is requiring many practices to rethink the way they run their businesses. Starting a telehealth solution is a vital first step that will provide supplemental income to offset your declining in-person patient visits. </p>
    <Link to="./news_and_insights/telehealth-covid-19">Read more <FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
   </div>

   <div className="ctr-page-listing-unit">
    <Link to="./news_and_insights/revenue_cycle_management__choosing_the_right_provider"><img src={img1} alt="image for article" /></Link>
    <h3>Revenue Cycle Management: Choosing The Right Provider Can Make All the Difference</h3>
    <p>Few business choices impact the success or failure of your medical practice as much as your Revenue Cycle Management (RCM) partner. </p>
    <Link to="./news_and_insights/revenue_cycle_management__choosing_the_right_provider">Read more <FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
   </div>

   <div className="ctr-page-listing-unit">
    <Link to="./news_and_insights/three_powerful_ways_independent_physicians_can_banish_admin_burden"><img src={img3} alt="image for article " /></Link>
    <h3>Three Powerful Ways Independent Physicians Can Banish Admin Burden</h3>
    <p>In today’s competitive medical practice marketplace, it can be challenging for independent physicians to thrive on their own.</p>
    <Link to="./news_and_insights/three_powerful_ways_independent_physicians_can_banish_admin_burden">Read more <FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
   </div>

   <div className="ctr-page-listing-unit">
    <Link to="./news_and_insights/four_myths_that_fuel_physician_burnout"><img src={img2} alt="image for article " /></Link>
    <h3>Four Myths that Fuel Physician Burnout—And How to Combat Them</h3>
    <p>You’re emotionally exhausted. You don’t feel a personal connection to your practice.</p>
    <Link to="./news_and_insights/four_myths_that_fuel_physician_burnout">Read more <FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
   </div>

  </section>

 </Layout>
)

export default SecondPage
